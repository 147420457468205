import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

import Image404 from "../images/404.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header title="404"/>
    <div class="fluid-container">
      <div class="row mtop-default mbottom-small">
        <div class="col-xs-12 col-sm-3 ipad-col-sm3 hidden-xs">
          <div class="bg-primary full-width image-panel">
            <img class="image" src={Image404}></img>
          </div>
        </div>
        <div class="col-xs-12 col-sm-9">
          <div class="padding-20 bg-tertiary mbottom-default">
            <h1>Onze excuses</h1>
            <h2>404 pagina</h2>
            <p>De pagina die u zonet heeft opgevraagd, bestaat niet op dit webadres.<br></br>
              Deze pagina werd mogelijk verplaatst, vervangen of verwijderd.</p>
          </div>
            <div class="pbottom-large mbottom-default border-bottom">
              <h3>Wat kunt u doen?</h3>
              <p>Meld het probleem aan de verantwoordelijke.</p>
              <h3>Kreeg u deze foutmelding na het klikken op een link op deze website?</h3>
              <p>Keer dan terug naar de vorige pagina: onderaan vindt u het e-mailadres van de verantwoordelijke medewerker. Indien u hem op de hoogte brengt van het probleem, kan hij het probleem oplossen en u verwijzen naar de juiste pagina. </p>
            </div>
          </div>
        </div>
      </div>
  </Layout>
)

export default NotFoundPage
